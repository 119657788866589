import { computed } from '@nuxtjs/composition-api';
import { useBuyingOption } from '~/bbrTheme/modules/catalog/stores/buyingOption';
import { getAttributeValue, getSellByCaseOnly } from '~/bbrTheme/modules/catalog/getters/productGetters';
import { ProductInterface } from '~/modules/GraphQL/types';

export function useVariantBottleInfo(product: ProductInterface | null) {
  const { isForDeliverySelected, isInBondSelected } = useBuyingOption();
  const caseOrderUnit = getAttributeValue(product, 'case_order_unit');
  const bottleVolume = getAttributeValue(product, 'bottle_volume');
  const bottleOrderUnit = getAttributeValue(product, 'bottle_order_unit');
  const sellByCaseOnly = getSellByCaseOnly(product);

  /**
   * Get the bottle info based on the buying option for list view
   * 
   * @remarks 
   * 
   * Buying Option "For Delivery" and "In Bond" have the following rules:
   * - "sell by case only" attribute is an integer, typecasted to boolean
   * - "sell by case only" is ignored if "In Bond" is selected
   * - if "sell by case only" is true, add "Case" to the label for "For Delivery" buying option
   * - if "sell by case only" is false, do not add "Case" to the label for "For Delivery" buying option
   * - if "In Bond" is selected, always display caseOrderUnit
   * 
   * Example #1
   * - "buying_option" = "For Delivery"
   * - "sell_by_case_only" = true
   * - "case_order_unit" = 6;
   * - "bottle_volume" = 75;
   * - "bottle_order_unit" = "cl", 
   * Result: 6 x 75cl - Case
   * 
   * Example #2
   * - "buying_option" = "For Delivery"
   * - "sell_by_case_only" = false
   * - "case_order_unit" = 1;
   * - "bottle_volume" = 75;
   * - "bottle_order_unit" = "cl", 
   * Result: 1 x 75cl
   * 
   * Example #3
   * - "buying_option" = "In Bond"
   * - "sell_by_case_only" = true
   * - "case_order_unit" = 6;
   * - "bottle_volume" = 75;
   * - "bottle_order_unit" = "cl", 
   * Result: 6 x 75cl - Case
   */
  const listBottleInfo = () => {
    if (!product) {
      return '';
    }

    if (isForDeliverySelected() && sellByCaseOnly) {
      return `${caseOrderUnit} x ${bottleVolume} ${bottleOrderUnit.toLowerCase()} - Case`;
    } else if (isForDeliverySelected() && !sellByCaseOnly) {
      return `1 x ${bottleVolume} ${bottleOrderUnit.toLowerCase()}`;
    } else if (isInBondSelected()) {
      // "Sell by case only" is ignored if "In Bond" is selected
      return `${caseOrderUnit} x ${bottleVolume} ${bottleOrderUnit.toLowerCase()}`;
    }
  };

  // Get the bottle info based on the buying option for grid view
  const gridBottleInfo = () => {
    if (!product) {
      return '';
    }

    if (isForDeliverySelected() && sellByCaseOnly) {
      return `case (${caseOrderUnit} x ${bottleVolume})`;
    } else if (isForDeliverySelected() && !sellByCaseOnly) {
      return `${bottleOrderUnit.toLowerCase()} (${bottleVolume})`;
    } else if (isInBondSelected()) {
      // "Sell by case only" is ignored if "In Bond" is selected
      return `case (${caseOrderUnit} x ${bottleVolume})`;
    }
  };

  return {
    listBottleInfo,
    gridBottleInfo,
  }
}

export default useVariantBottleInfo;
