


























































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfPrice } from '@storefront-ui/vue';
import { getPrice } from '~/modules/catalog/product/getters/productGetters';
import { useVariantBottleInfo } from '~/bbrTheme/composables';

export default defineComponent({
  name: 'CategoryProductPrice',
  components: {
    SfPrice,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // TODO: Types are not provided for the product prop
    const productPrice = computed(() => getPrice(props.product.product));
    const { gridBottleInfo } = useVariantBottleInfo(props.product.product);

    return {
      productPrice,
      gridBottleInfo,
    }
  }
});

